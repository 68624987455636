
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import ExploitationContent from "@/components/exploitations/ExploitationContent.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { notify } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "exploitations",
  components: {
    ExploitationContent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store
        .dispatch(Actions.FETCH_EXPLOITATIONS)
        .then((response) => {
          store.dispatch(Actions.FETCH_OTEX).then(() => {
            setCurrentPageTitle("Mes Exploitations");
          });
        })
        .catch(() => {
          router.push({ name: "homepage" });
          notify({
            text: "Une erreur est survenue. Veuillez réessayer ultérieurement",
            color: "error",
          });
        });
    });

    const contentKey = ref<number>(0);

    const getExploitations = computed(() => store.getters.getExploitations);
    const getExploitationsToUpdate = computed(
      () => store.getters.getExploitationsToUpdate
    );
    const mandatoryFieldToUpdate = computed(
      () => store.getters.getmandatoryFieldToUpdate
    );

    return {
      contentKey,
      getExploitations,
      getExploitationsToUpdate,
      mandatoryFieldToUpdate,
    };
  },
});
